import React from "react";
import ReactDOM from "react-dom/client";

import { configureStore } from "@reduxjs/toolkit";
import App from "./components/App";
import { Provider } from "react-redux";
import appReducer from "./store";
import { settings } from "./store/queries/settings";
import { dashboard } from "./store/queries/Dashboard";
import { product } from "./store/queries/product";
import { profile } from "./store/queries/profile";
import { notificationRtk } from "./store/queries/notifications";
import { roles } from "./store/queries/roles";
import { purpose } from "./store/queries/purpose";
import { delivery } from "./store/queries/delivery";
import { staffs } from "./store/queries/staffs";
import { currency } from "./store/queries/currency";
import { branches } from "./store/queries/branches";
import { user } from "./store/queries/user";
import { global } from "./store/queries/global";
import { orders } from "./store/queries/orders";
import { packages } from "./store/queries/packages";
import { bonusProgram } from "./store/queries/bonusProgram";
import { program } from "./store/queries/program";
import { customer } from "./store/queries/customer";
import { dailyMeditation } from "./store/queries/dailyMeditation";
import { questionnaire } from "./store/queries/questionnaire";
import { coupon } from "./store/queries/coupon";
import { review } from "./store/queries/review";
import { configure } from "./store/queries/configure";
import { testimonial } from "./store/queries/testimonials";
import { agent } from "./store/queries/agent";
import { announcement } from "./store/queries/announcement";
import { trainingVideo } from "./store/queries/trainingVideo";
import { trainingSession } from "./store/queries/trainingSession";
import { goal } from "./store/queries/goal";
import { coupons } from "./store/queries/coupons";
import { students } from "./store/queries/students";
import { detailCoupon } from "./store/queries/detailCoupon";
import { payouts } from "./store/queries/payouts";
import { sellerLearning } from "./store/queries/SellerModule/sellerLearning";
import { sellerTraining } from "./store/queries/SellerModule/sellerTraining";
import { myStudents } from "./store/queries/SellerModule/myStudents";
import { feedbacks } from "./store/queries/SellerModule/feedbacks";
import { reportedIssues } from "./store/queries/SellerModule/reportedIssues";
import { studentFeedbacks } from "./store/queries/SellerModule/studentFeedbacks";
import { studentGoals } from "./store/queries/SellerModule/studentGoals";
import { studentPayout } from "./store/queries/SellerModule/studentPayout";
import { studentIssues } from "./store/queries/SellerModule/studentIssues";
import { sellerCoupons } from "./store/queries/SellerModule/sellerCoupons";
import { manageProfile } from "./store/queries/SellerModule/manageProfile";
import { sellerAnnouncements } from "./store/queries/SellerModule/sellerAnnouncements";
import { sellerPayout } from "./store/queries/SellerModule/sellerPayout";
import { sellerDashboard } from "./store/queries/SellerModule/sellerDashboard";
import { agentPayouts } from "./store/queries/agentPayouts";
import { issues } from "./store/queries/issues";
import { monthlyPayouts } from "./store/queries/monthlyPayouts";
import { payoutHistory } from "./store/queries/payoutHistory";
import { order } from "./store/queries/order";
import { cms } from "./store/queries/cms";
import { campaigns } from "./store/queries/campaigns";

const store = configureStore({
  reducer: appReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(
      settings.middleware,
      product.middleware,
      roles.middleware,
      dashboard.middleware,
      profile.middleware,
      notificationRtk.middleware,
      purpose.middleware,
      delivery.middleware,
      staffs.middleware,
      currency.middleware,
      branches.middleware,
      user.middleware,
      global.middleware,
      orders.middleware,
      packages.middleware,
      bonusProgram.middleware,
      program.middleware,
      customer.middleware,
      dailyMeditation.middleware,
      questionnaire.middleware,
      coupon.middleware,
      review.middleware,
      configure.middleware,
      testimonial.middleware,
      agent.middleware,
      announcement.middleware,
      trainingVideo.middleware,
      trainingSession.middleware,
      goal.middleware,
      coupons.middleware,
      agentPayouts.middleware,
      students.middleware,
      detailCoupon.middleware,
      payouts.middleware,
      sellerLearning.middleware,
      sellerTraining.middleware,
      myStudents.middleware,
      feedbacks.middleware,
      reportedIssues.middleware,
      studentFeedbacks.middleware,
      studentGoals.middleware,
      studentPayout.middleware,
      studentIssues.middleware,
      sellerCoupons.middleware,
      manageProfile.middleware,
      sellerAnnouncements.middleware,
      sellerPayout.middleware,
      sellerDashboard.middleware,
      issues.middleware,
      monthlyPayouts.middleware,
      payoutHistory.middleware,
      order.middleware,
      cms.middleware,
      campaigns.middleware
    ),
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
