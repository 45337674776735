import { useMemo } from "react";
import { useSelector } from "react-redux";

// import Roles from "../components/Configure/roles";
import AgentCoupons from "../components/Agent/agentCoupons";
import Agents from "../components/Agent/agents";
import Announcement from "../components/Agent/announcement";
import TrainingSession from "../components/Agent/trainingSession";
import TrainingVideo from "../components/Agent/trainingVideo";
import BonusPrograms from "../components/BonusPrograms/bonusPrograms";
import Testimonials from "../components/Configure/testimonials";
import Coupon from "../components/Coupon";
import Customers from "../components/Customers/customer";
import DailyMeditation from "../components/DailyMeditation";
import Dashboard from "../components/Dashboard";
import Package from "../components/Package";
import Programs from "../components/Program/programs";
import Questionnaire from "../components/Questionnaire";
import Reviews from "../components/Reviews";
import Roles from "../components/Role/roles";
import MyLearning from "../components/SellerModule/AgentTraining/myLearning";
import MyTraining from "../components/SellerModule/AgentTraining/myTraining";
import SellerAnnouncement from "../components/SellerModule/SellerAnnouncement";
import SellerCoupon from "../components/SellerModule/SellerCoupon";
import SellerPayouts from "../components/SellerModule/SellerPayouts";
import Feedbacks from "../components/SellerModule/StudentActivity/feedbacks";
import MyStudents from "../components/SellerModule/StudentActivity/myStudents";
import ReportedIssues from "../components/SellerModule/StudentActivity/reportedIssues";
import UserAdmin from "../components/Users/user";
import AgentPayouts from "../components/Agent/agentPayouts";
import Issues from "../components/Configure/issues";
import MonthlyPayouts from "../components/Agent/monthlyPayouts";
import PayoutHistory from "../components/Agent/payoutHistory";
import Orders from "../components/Orders";
import PrivacyPolicy from "../components/Cms/PrivacyPolicy";
import TermsAndConditions from "../components/Cms/TermsAndConditions";
import Faq from "../components/Cms/Faq";
import Campaign from "../components/Campaign";

const useRoutes = () => {
  const globalState = useSelector((state) => state.global);

  const activeUser =
    JSON.parse(localStorage.getItem("USER_DETAILS")) ?? "no data found";

  const DashboardComponent = [
    {
      component: <Dashboard />,
      permission: "Dashboard",
      path: activeUser?.id === 1 ? "dashboard?activeTab=students" : "dashboard",
    },
  ];

  const PackageComponent = [
    {
      component: <Package />,
      permission: "Package",
      path: "packages",
    },
  ];

  const BonusComponents = [
    {
      component: <BonusPrograms />,
      permission: "Bonus Programs",
      path: "bonus-programs",
    },
  ];

  const ReviewComponents = [
    {
      component: <Reviews />,
      permission: "Reviews",
      path: "review",
    },
  ];

  const ProgramComponents = [
    {
      component: <Programs />,
      permission: "Programs",
      path: "program",
    },
  ];

  const OrderComponents = [
    {
      component: <Orders />,
      permission: "Orders",
      path: "order",
    },
  ];

  const ConfigureComponents = [
    {
      component: <Roles />,
      permission: "Roles",
      path: "role",
    },
    {
      component: <UserAdmin />,
      permission: "Admin",
      path: "user",
    },
    {
      component: <DailyMeditation />,
      permission: "Daily Meditation",
      path: "daily-meditation",
    },
    {
      component: <Questionnaire />,
      permission: "Questionnaire",
      path: "questionnaire",
    },
    {
      component: <Coupon />,
      permission: "Coupons",
      path: "coupon",
    },
    {
      component: <Testimonials />,
      permission: "Testimonial",
      path: "testimonial",
    },
    {
      component: <Issues />,
      permission: "Customer Issues",
      path: "issues",
    },
  ];

  const AgentComponents = [
    {
      component: <Agents />,
      permission: "Agent",
      path: "agent",
    },
    {
      component: <Announcement />,
      permission: "Announcement",
      path: "announcement",
    },
    {
      component: <TrainingVideo />,
      permission: "Training Video",
      path: "training-video",
    },
    {
      component: <TrainingSession />,
      permission: "Announcement",
      path: "training-session",
    },
    {
      component: <AgentCoupons />,
      permission: "Coupon",
      path: "agent-coupon",
    },
    {
      component: <AgentPayouts />,
      permission: "Payouts",
      path: "agent-payouts",
    },
    {
      component: <MonthlyPayouts />,
      permission: "Monthly Payout",
      path: "monthly-payouts",
    },
    {
      component: <PayoutHistory />,
      permission: "Payout History",
      path: "history",
    },
  ];

  const CustomerComponents = [
    {
      component: <Customers />,
      permission: "Customer",
      path: "customer",
    },
  ];

  //AGENT MODULE MENU

  const StudentsActivityComponents = [
    {
      component: <MyStudents />,
      permission: "My Students",
      path: "my-student",
    },
    {
      component: <ReportedIssues />,
      permission: "Reported Issues",
      path: "reported-issue",
    },
    {
      component: <Feedbacks />,
      permission: "Feedbacks",
      path: "feedback",
    },
  ];

  const SellerCouponComponents = [
    {
      component: <SellerCoupon />,
      permission: "Coupon",
      path: "seller-coupon",
    },
  ];

  const CouponComponents = [
    {
      component: <Coupon />,
      permission: "Coupons",
      path: "coupons",
    },
  ];

  const PayoutsComponents = [
    {
      component: <SellerPayouts />,
      permission: "Payouts",
      path: "seller-payouts",
    },
  ];

  const AnnouncementComponents = [
    {
      component: <SellerAnnouncement />,
      permission: "Announcement",
      path: "seller-announcement",
    },
  ];

  const CampaignComponents = [
    {
      component: <Campaign />,
      permission: "Campaigns",
      path: "campaigns",
    },
  ];

  const TrainingComponents = [
    {
      component: <MyTraining />,
      permission: "Training",
      path: "training/upcoming",
    },
    {
      component: <MyLearning />,
      permission: "My Learning",
      path: "my-learning",
    },
  ];

  const CMSComponents = [
    {
      component: <PrivacyPolicy />,
      permission: "Privcy Policy",
      path: "cms/privacy-policy",
    },
    {
      component: <TermsAndConditions />,
      permission: "Terms And Condition",
      path: "cms/terms-and-condition",
    },
    {
      component: <Faq />,
      permission: "Faq",
      path: "cms/faq",
    },
  ];

  const drawerMenuPermission = useMemo(() => {
    let menus = globalState?.dashboard_permissions?.flatMap?.((menu) => [
      ...menu.sub_menu_permissions?.map((side_menu) => side_menu.sub_menu_name),
    ]);
    if (!menus) {
      menus = [];
    }
    menus = [
      ...menus,
      "Appearance",
      // "Dashboard"
    ];
    return menus;

    //eslint-disable-next-line
  }, [globalState.dashboard_permissions, location.pathname]);

  function hasPermission(permission) {
    const user = {
      permissions: drawerMenuPermission,
    };
    return user.permissions?.includes(permission);
  }

  const allowedDashboardComponent = DashboardComponent.filter(
    ({ permission }) => hasPermission(permission)
  );

  const allowedPackageComponent = PackageComponent.filter(({ permission }) =>
    hasPermission(permission)
  );

  const allowedCustomerComponent = CustomerComponents.filter(({ permission }) =>
    hasPermission(permission)
  );

  const allowedProgramComponents = ProgramComponents.filter(({ permission }) =>
    hasPermission(permission)
  );

  const allowedBonusComponents = BonusComponents.filter(({ permission }) =>
    hasPermission(permission)
  );

  const allowedCampaignComponents = CampaignComponents.filter(({ permission }) =>
    hasPermission(permission)
  );

  const allowedReviewComponents = ReviewComponents.filter(({ permission }) =>
    hasPermission(permission)
  );

  const allowedOrderComponents = OrderComponents.filter(({ permission }) =>
    hasPermission(permission)
  );

  const allowedConfigureComponents = ConfigureComponents.filter(
    ({ permission }) => hasPermission(permission)
  );

  const allowedAgentComponents = AgentComponents.filter(({ permission }) =>
    hasPermission(permission)
  );

  const allowedCmsComponents = CMSComponents.filter(({ permission }) =>
    hasPermission(permission)
  );

  //AGENT MODULE

  const allowedStudentsActivityComponents = StudentsActivityComponents.filter(
    ({ permission }) => hasPermission(permission)
  );

  const allowedSellerCouponComponents = SellerCouponComponents.filter(
    ({ permission }) => hasPermission(permission)
  );

  const allowedCouponComponents = CouponComponents.filter(({ permission }) =>
    hasPermission(permission)
  );

  const allowedTrainingComponents = TrainingComponents.filter(
    ({ permission }) => hasPermission(permission)
  );

  const allowedPayoutsComponents = PayoutsComponents.filter(({ permission }) =>
    hasPermission(permission)
  );

  const allowedAnnouncementComponents = AnnouncementComponents.filter(
    ({ permission }) => hasPermission(permission)
  );

  return {
    allowedDashboardComponent,
    allowedPackageComponent,
    allowedBonusComponents,
    allowedProgramComponents,
    allowedCustomerComponent,
    allowedReviewComponents,
    allowedConfigureComponents,
    allowedAgentComponents,
    allowedAnnouncementComponents,
    allowedPayoutsComponents,
    allowedTrainingComponents,
    allowedStudentsActivityComponents,
    allowedSellerCouponComponents,
    allowedCouponComponents,
    allowedOrderComponents,
    allowedCmsComponents,
    allowedCampaignComponents
  };
};

export default useRoutes;
