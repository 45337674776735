import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  currentPage: 1,
  scrollPage: 1,
  currentFilter: null,
  currentPageSize: 10,
  startDate: null,
  endDate: null,
  clearSelection: false,
  queryData: {},
  queryStatus: "idle",
  sortBy: "",
  sortOrder: "desc",
  search: "",
  showCreateModal: false,
  selectedId: "",
  selectedItemsDetails: "",
  is_edit: false,
  start_date: "",
  end_date: "",
  status: "",
  coupon_type: "",
  created_from: "",
  created_to: "",
};

const campaignsSlice = createSlice({
  name: "campaigns",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    }
  }
});

export const { updateConfig} =
  campaignsSlice.actions;

export default campaignsSlice.reducer;
